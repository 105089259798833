import React, { Component } from "react";
import getWeb3 from "./utils/getWeb3";
import "bootstrap/dist/css/bootstrap.css";
import ipfs from "./ipfs";
import $ from "jquery";
import Web3 from "web3";
import "./App.css";
import abi from "./abi.json";
import logo from "./logo.png";
var Asset;
var web3;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ipfsHash: "",
      link: '',
      web3: null,
      buffer: null,
      account: null,
      form_data: {
        owner_name: null,
        address: null,
        details: null,
        year: null,
      },
      tAsset: {
        owner_name: null,
        address: null,
        asset_no: null
      },
      vTrial: {
        asset_no: null
      },
      gAccess: {
        asset_no: null,
        address: null,
        time: null
      },
      vOwner: {
        asset_no: null
      },
      dAccess: {
        asset_no: null,
        address: null,
      },
    };
  }

  componentWillMount() {
    // Get network provider and web3 instance.
    // See utils/getWeb3 for more info.

    getWeb3
      .then((results) => {
        this.setState({
          web3: results.web3,
        });
        this.initiate(results.web3);
      })
      .catch(() => {
        console.log("Error finding web3.");
      });
  }

  initiate = async (web3) => {
    try {
      var accounts = await web3.eth.getAccounts();
      this.setState({
        account: accounts[0],
      });
      console.log("Accounts :::::", accounts);
      const address = "0x6957EB086875c47509F7bD9e8594F536bD4a3565";
      Asset = new web3.eth.Contract(abi.abi, address);
    } catch (error) {
      console.log("Error  :::::", error);
    }
  };

  captureFile = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new window.FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      this.setState({ buffer: Buffer(reader.result) });
      console.log("buffer", this.state.buffer);
    };
  };

  onchange = (event) => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState(({ form_data }) => ({
      form_data: { ...form_data, [name]: value },
    }));
  };

  t_asset_onchange = event => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState(({ tAsset }) => ({
      tAsset: { ...tAsset, [name]: value },
    }));
  }

  v_asset_onchange = event => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState(({ vTrial }) => ({
      vTrial: { ...vTrial, [name]: value },
    }));
  }

  g_asset_onchange = event => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState(({ gAccess }) => ({
      gAccess: { ...gAccess, [name]: value },
    }));
  }

  v_owner_onchange = event => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState(({ vOwner }) => ({
      vOwner: { ...vOwner, [name]: value },
    }));
  }

  d_asset_onchange = event => {
    var name = event.target.name;
    var value = event.target.value;
    this.setState(({ dAccess }) => ({
      dAccess: { ...dAccess, [name]: value },
    }));
  }

  cAsset = async () => {
    const { owner_name, address, details, year } = this.state.form_data;
    var no = await Asset.methods.assets(1).call();
    console.log("no :::::", no);
    ipfs.files.add(this.state.buffer, async (error, result) => {
      if (error) {
        console.log("error :::::", error);
      } else {
        console.log("data :::::", owner_name, address, details, year);
        await Asset.methods
          .createAsset(owner_name, address, details, year, result[0].hash)
          .send({ from: this.state.account, gas: 1000000 })
          .then(async (result) => {
            console.log("result :::::", result);
            // var no = await Asset.methods.assetNo().call();
            // console.log("no :::::", no);
            $("#view01").html(
              "A new Asset is created with asset #" +
                result.events.NewAssetNo.returnValues._assetno
            );
          })
          .catch((err) => console.log("Error :::::", err));
      }
    });
  };

  tAsset = async () => {
    const { owner_name, address, asset_no } = this.state.tAsset;
    await Asset.methods
      .transferAsset(owner_name, address, asset_no)
      .send({ from: this.state.account, gas: 1000000 })
      .then((result) => {
        console.log("result ----->", result);
        $("#view02").html("Asset is transfered to new Owner");
      }).catch(err => {
        console.log('Error :::::', err);
      })
  }

  VTrail = async () => {
    const { asset_no } = this.state.vTrial;
    console.log('asset no :::::', asset_no);
    await Asset.methods
      .viewTrail(asset_no)
      .call()
      .then((result) => {
        $("#view03").html("Trail of ownerhip :" + result[0]);
        this.setState({
          link: "https://ipfs.io/ipfs/" + result[1]
        })
        console.log(result);
      })
  }

  gAccess = async () => {
    const { asset_no, address, time } = this.state.gAccess;
    console.log('ass :::::', asset_no);
    await Asset.methods
      .giveViewAccess(asset_no, address, time)
      .send({
        from: this.state.account,
        gas: 1000000,
      })
      .then(function (result) {
        $("#view04").html(
          "View Access has been given for a limited period of time"
        );
        console.log(result);
      }).catch(err => {
        console.log('Error :::::', err);
      })
  }

  VOwner = async () => {
    const { asset_no } = this.state.vOwner;
    await Asset.methods
      .viewAssetDetail(asset_no)
      .call({ from: this.state.account, gas: 1000000 })
      .then(function (result) {
        $("#view05").html("Current Owner Name : " + result);
        console.log(result);
      }).catch(err => {
        $("#view05").html("Times up");
        console.log('Error :::::', err);
      })
  }

  DOwner = async () => {
    const { asset_no, address } = this.state.dAccess
    await Asset.methods
      .delegateOwner(asset_no, address)
      .send({ from: this.state.account, gas: 1000000 })
      .then(function (result) {
        $("#view06").html("Owner delegated to :" + address);
        console.log(result);
      }).catch(err => {
        console.log('error :::::', err);
      })
  }

  render() {
    return (
      <>
        <div style={{ textAlign: "center" }}>
          <img
            src={logo}
            alt="Vatzcar logo"
            width={500}
            height={250}
            style={{ width: "50%", textAlign: "center" }}
          />
          <h1 style={{ textAlign: "center" }}>
            Welcome to Vatzcar Smart Contract.
          </h1>
          <br />
          <br />
          <h5 style={{ textAlign: "center" }}>
            Create a blockchain contract for storing and sharing data and other
            parameter of an asset.
          </h5>
          <br />
          <h5 style={{ textAlign: "center" }}>Main features as follows :</h5>
          <br />
          <h5 style={{ textAlign: "center" }}>
            1. Data stored &amp; shared should be immutable.
          </h5>
          <br />
          <h5 style={{ textAlign: "center" }}>
            2. Asset ownership should be transferable.
          </h5>
          <br />
          <h5 style={{ textAlign: "center" }}>
            3. Trail of ownership should be visible.
          </h5>
          <br />
          <h5 style={{ textAlign: "center" }}>
            4. Asset data should be visible for a limited period to third party
            on demand.
          </h5>
          <br />
          <h5 style={{ textAlign: "center" }}>
            5. Owner of asset can delegate ownership to a 'temporary owner'
          </h5>
          <br />
          <div className="container-fluid">
            <br />
            <h3>CREATE NEW ASSET</h3>
            <br />
            <div className="form-row">
              <div className="form-group col-md-2">
                <input
                  type="text"
                  id="ca_param1"
                  name="owner_name"
                  placeholder="Asset Owner Name"
                  onChange={this.onchange}
                />
              </div>
              <div className="form-group col-md-2">
                <input
                  name="address"
                  type="text"
                  id="ca_param2"
                  placeholder="Eth address"
                  onChange={this.onchange}
                />
              </div>
              <div className="form-group col-md-2">
                <input
                  name="details"
                  type="text"
                  id="ca_param3"
                  placeholder="Other Detail"
                  onChange={this.onchange}
                />
              </div>
              <div className="form-group col-md-2">
                <input
                  name="year"
                  type="text"
                  id="ca_param4"
                  placeholder="Year Of Mfg."
                  onChange={this.onchange}
                />
              </div>
              <div className="form-group col-md-2">
                <input type="file" onChange={this.captureFile} />
              </div>
              <div className="form-group col-md-2">
                <button id="button" onClick={() => this.cAsset()}>
                  Create Asset
                </button>
                <br />
              </div>
              <b>
                <div id="view01" />
              </b>
              <br />
            </div>
            <br />
            <h3>TRANSFER ASSET</h3>
            <br />
            <div className="form-row">
              <div className="form-group col-md-2">
                <input
                  type="text"
                  id="ta_param1"
                  name = "owner_name"
                  onChange = {this.t_asset_onchange}
                  placeholder="New Asset Owner Name"
                />
              </div>
              <div className="form-group col-md-2">
                <input
                  type="text"
                  id="ta_param2"
                  name = "address"
                  onChange = {this.t_asset_onchange}
                  placeholder="New Eth address"
                />
              </div>
              <div className="form-group col-md-2">
                <input type="text" id="ta_param3" placeholder="Asset No." name = "asset_no"
                  onChange = {this.t_asset_onchange} />
              </div>
              <div className="form-group col-md-2">
                <button id="button" onClick={() => this.tAsset()}>
                  Transfer Asset
                </button>
                <br />
              </div>
              <b>
                <div id="view02" />
              </b>
              <br />
            </div>
            <br />
            <h3>VIEW TRAIL OF OWNERSHIP</h3>
            <br />
            <div className="form-row">
              <div className="form-group col-md-2">
                <input type="text" id="vt_param1" placeholder="Asset No." name="asset_no" onChange={this.v_asset_onchange} />
              </div>
              <div className="form-group col-md-2">
                <button id="button" onClick={() => this.VTrail()}>
                  View Trail
                </button>
                <br />
              </div>
              <b>
                <div id="view03" />
              </b>
              <img style = {{height: '15rem'}} src={this.state.link} alt=""/>
              <br />
            </div>
            <br />
            <h3>Give View Access for a limited Period of Time</h3>
            <br />
            <div className="form-row">
              <div className="form-group col-md-2">
                <input type="text" id="ga_param1" placeholder="NAsset No." name="asset_no" onChange={this.g_asset_onchange} />
              </div>
              <div className="form-group col-md-2">
                <input
                  type="text"
                  id="ga_param2"
                  placeholder="New Eth address"
                  name="address" 
                  onChange={this.g_asset_onchange}
                />
              </div>
              <div className="form-group col-md-2">
                <input
                  type="text"
                  id="ga_param3"
                  placeholder="Time in seconds"
                  name="time" 
                  onChange={this.g_asset_onchange}
                />
              </div>
              <div className="form-group col-md-2">
                <button id="button" onClick={() => this.gAccess()}>
                  Give View Access
                </button>
                <br />
              </div>
              <b>
                <div id="view04" />
              </b>
              <br />
            </div>
            <br />
            <h3>VIEW ASSET OWNER NAME</h3>
            <br />
            <div className="form-row">
              <div className="form-group col-md-2">
                <input type="text" id="VO_param1" placeholder="Asset No." name = "asset_no" onChange = {this.v_owner_onchange} />
              </div>
              <div className="form-group col-md-2">
                <button id="button" onClick={() => this.VOwner()}>
                  View Asset Owner
                </button>
                <br />
              </div>
              <b>
                <div id="view05" />
              </b>
              <br />
            </div>
            <br />
            <h3>DELEGATE OWNERSHIP</h3>
            <br />
            <div className="form-row">
              <div className="form-group col-md-2">
                <input type="text" id="DO_param1" placeholder="Asset No." name="asset_no" onChange={this.d_asset_onchange} />
              </div>
              <div className="form-group col-md-2">
                <input
                  type="text"
                  id="DO_param2"
                  placeholder="Delegate owner Eth address"
                  name="address" 
                  onChange={this.d_asset_onchange}
                />
              </div>
              <div className="form-group col-md-2">
                <button id="button" onClick={() => this.DOwner()}>
                  Delegate Owner
                </button>
                <br />
              </div>
              <b>
                <div id="view06" />
              </b>
              <br />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default App;
